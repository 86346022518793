import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import {
  getChoices,
  getFormInitValues,
  dynamicValidation,
  deboundRequest
} from '../../services/utils'
import {
  FormFieldProps,
  FormsProps,
  LAYOUT_TEMPLATE,
  SelectOption
} from '../../services/api/types'
import { submitForm, submitFormWithFiles } from '../../services/api/cms'
import { EnquiryFormRow } from './MemberEnquiryForm.styled'
import * as F from './Form.styled'
import * as G from '../../styles/global.styles'
import { Button } from '../base/Button'
import {
  EnquireFormWrapper,
  EnquireFormContent,
  EnquireFormHeader,
  EnquireFormHeroWrapper
} from './OwnerEnquireForm.styled'
import {
  TextAreaInput,
  DropdownStyled,
  TextInput,
  CheckBox,
  Radio,
  BlockTitle,
  GymsSelect,
  FileInput,
  DatePickerStyled,
  SVGIcon,
  IconEnums
} from '../base'
import useGymOptions from '../../hooks/useGymOptions'
import { RECAPTCHA_KEY, PAGE_TEMPLATE } from '../../services/api/constants'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { getCookieFromBrowser } from '../../services/api/cookies'
import PrivacyField from './PrivacyField'

export interface OwnerEnquireFormProps extends FormsProps {
  formHeader?: string
  submitText: string
  post_redirect_page?: string
  isHero?: boolean
  template?: LAYOUT_TEMPLATE
  onSuccess?: Function
}

export const OwnerEnquireForm = (props: OwnerEnquireFormProps) => {
  const {
    formHeader,
    slug,
    fields,
    submitText,
    isHero,
    post_redirect_page,
    template,
    onSuccess
  } = props

  const [success, setSuccess] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')

  const hasRecaptcha =
    fields &&
    fields.length > 0 &&
    fields?.find(it => it.field_block === 'recaptcha')
      ? true
      : false

  const [recaptchaDisableSubmitHero, setRecaptchaDisableSubmitHero] = useState(
    hasRecaptcha
  )
  const [recaptchaDisableSubmit, setRecaptchaDisableSubmit] = useState(
    hasRecaptcha
  )

  useEffect(() => {
    if (!window.RECAPTCHA_INITIALIZED && hasRecaptcha) {
      loadReCaptcha(RECAPTCHA_KEY)
      window.RECAPTCHA_INITIALIZED = true
    }
  }, [hasRecaptcha])

  let fileFieldText: Array<string> = []
  fields &&
    fields.length > 0 &&
    fields.map(field => {
      if (field.type === 'file') {
        fileFieldText.push(field.name)
      }
    })
  const hasFile = fileFieldText && fileFieldText.length > 0

  const formik = useFormik({
    initialValues: getFormInitValues(props.fields),
    validationSchema: dynamicValidation(props.fields),
    onSubmit: async values => {
      if (!slug) {
        return
      }
      let fileData: any
      let fieldData: any
      if (hasFile) {
        fileData = Object.keys(values)
          .filter(key => fileFieldText.includes(key))
          .reduce((obj: any, key: string) => {
            obj[key] = values[key]
            return obj
          }, {})
        fieldData = Object.keys(values)
          .filter((key: string) => !fileFieldText.includes(key))
          .reduce((obj: any, key: string) => {
            obj[key] = values[key]
            return obj
          }, {})
      }

      const utmSourceCookie = getCookieFromBrowser('utm_source')
      const utmCampaignCookie = getCookieFromBrowser('utm_campaign')
      if (utmSourceCookie && utmCampaignCookie) {
        values['utm_source'] = utmSourceCookie
        values['utm_campaign'] = utmCampaignCookie
      }

      let res = hasFile
        ? await submitFormWithFiles(null, slug, fieldData, fileData)
        : await submitForm(null, slug, values)
      if (res.ok) {
        setResponseMessage(res.success_message)
        setSuccess(true)
        if (post_redirect_page) {
          window.location.assign(post_redirect_page)
        } else {
          onSuccess && onSuccess()
        }
      } else {
        setResponseMessage(res.error_message)
        setSuccess(false)
        throw responseMessage
      }
    }
  })
  const textAreaInput = (
    field: FormFieldProps,
    isHero?: boolean,
    isInvalid?: boolean
  ) => {
    switch (field.field_block) {
      case 'label':
        return (
          <F.FormGroup col={1}>
            <F.FormLabel>{field.label}</F.FormLabel>
          </F.FormGroup>
        )
      case 'heading':
        return (
          <BlockTitle textTransform='uppercase' className='hero-with-form'>
            {field.label}
          </BlockTitle>
        )
      case 'multiline':
        return (
          <TextAreaInput
            rows={isHero ? 3 : 4}
            cols={28}
            name={field.name}
            borderColor={isInvalid ? 'red' : undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        )
    }
  }
  const {
    gymOptions,
    isLoadingGyms,
    setChoosenState,
    setChoosenPostCode
  } = useGymOptions('', '', fields, props.slug)

  // Show label on YOU_FITNESS template
  const getLabel = (label?: string) => {
    return template === PAGE_TEMPLATE.YOU_FITNESS && label ? (
      <label>{label}</label>
    ) : null
  }
  const isYouFitnessTemplate = template === PAGE_TEMPLATE.YOU_FITNESS
  const dropdownComponents = isYouFitnessTemplate
    ? {
        IndicatorSeparator: () => null,
        DropdownIndicator: () => (
          <span style={{ position: 'relative', right: 6 }}>
            <SVGIcon name={IconEnums['icon-angle-down']} />
          </span>
        )
      }
    : null

  return isHero ? (
    <EnquireFormHeroWrapper template={template}>
      <F.Form onSubmit={formik.handleSubmit}>
        {success ? (
          <>
            <EnquireFormHeader>
              {'Your enquiry has been sent.'}
            </EnquireFormHeader>
            <G.Space height={1} />
          </>
        ) : null}
        {formHeader && !success ? (
          <EnquireFormHeader>{formHeader}</EnquireFormHeader>
        ) : null}
        {!success &&
          fields?.map((field, idx) => {
            let inputComp
            const isInvalid =
              formik && formik.errors[field.name] && formik.touched[field.name]
            switch (field.type) {
              case 'text_area': {
                inputComp = textAreaInput(field, isHero, !!isInvalid)
                break
              }
              case 'select': {
                const selectOptions = getChoices(field.choices)
                if (field.field_block == 'gym') {
                  inputComp = (
                    <>
                      {getLabel(field.help_text || field.label)}
                      <GymsSelect
                        placeholder={field.help_text || field.label}
                        classNamePrefix={
                          isYouFitnessTemplate
                            ? isInvalid
                              ? 'error you-fitness'
                              : 'you-fitness'
                            : ''
                        }
                        name={field.name}
                        defaultOptions={gymOptions}
                        onChange={(option: SelectOption) =>
                          formik.setFieldValue(field.name, option.value)
                        }
                        components={dropdownComponents}
                        onBlur={formik.handleBlur}
                        isDisabled={isLoadingGyms}
                        isLoading={isLoadingGyms}
                        formSlug={props.slug}
                      />
                    </>
                  )
                } else {
                  inputComp = (
                    <>
                      {getLabel(field.help_text || field.label)}
                      <DropdownStyled
                        placeholder={field.help_text || field.label}
                        classNamePrefix={
                          isYouFitnessTemplate
                            ? isInvalid
                              ? 'error you-fitness'
                              : 'you-fitness'
                            : ''
                        }
                        name={field.name}
                        type={field.type}
                        options={selectOptions}
                        components={dropdownComponents}
                        onChange={(option: SelectOption) => {
                          if (field.name === 'state') {
                            setChoosenState(option.value)
                          }
                          formik?.setFieldValue(field.name, option.value)
                        }}
                        onBlur={formik?.handleBlur}
                      />
                    </>
                  )
                }
                break
              }
              case 'checkbox': {
                const selectOptions = getChoices(field.choices)
                inputComp = (
                  <>
                    {selectOptions.map(op => {
                      return (
                        <CheckBox
                          name={field.name}
                          type={field.type}
                          value={op.value}
                          label={op.label}
                          color='black'
                          borderColor={isInvalid ? 'red' : 'white'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      )
                    })}
                  </>
                )
                break
              }
              case 'radio': {
                const selectOptions = getChoices(field.choices)
                inputComp = (
                  <>
                    {selectOptions.map(op => {
                      return (
                        <Radio
                          name={field.name}
                          type={field.type}
                          value={op.value}
                          label={op.label}
                          color='black'
                          borderColor={isInvalid ? 'red' : 'white'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      )
                    })}
                  </>
                )
                break
              }
              case 'file': {
                inputComp = (
                  <FileInput
                    name={field.name}
                    type={field.type}
                    text={field.label}
                    className={
                      isYouFitnessTemplate
                        ? `you-fitness-fileinput ${
                            isInvalid ? 'you-fitness-error' : ''
                          }`
                        : ''
                    }
                    color='black'
                    borderColor={isInvalid ? 'red' : 'white'}
                    onChange={(event: any) =>
                      formik?.setFieldValue(
                        field.name,
                        event.currentTarget.files[0]
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                )
                break
              }
              case 'text': {
                if (field.field_block == 'date') {
                  inputComp = (
                    <>
                      {getLabel(field.help_text || field.label)}
                      <DatePickerStyled
                        placeholder={field.help_text || field.label}
                        name={field.name}
                        setFieldValue={formik?.setFieldValue}
                        showYearDropdown
                      />
                    </>
                  )
                } else if (field.field_block == 'datetime') {
                  inputComp = (
                    <DatePickerStyled
                      timePicker={true}
                      name={field.name}
                      setFieldValue={formik?.setFieldValue}
                      showYearDropdown
                    />
                  )
                } else {
                  inputComp = (
                    <>
                      {getLabel(field.help_text || field.label)}
                      <TextInput
                        placeholder={field.help_text || field.label}
                        name={field.name}
                        type={field.type}
                        color='black'
                        borderColor={isInvalid ? 'red' : 'white'}
                        onChange={(e: any) => {
                          if (field.name === 'postcode') {
                            deboundRequest(setChoosenPostCode, e.target.value)
                          }
                          formik.setFieldValue(field.name, e.target.value)
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </>
                  )
                }
                break
              }
              case 'input': {
                if (field.field_block == 'recaptcha') {
                  inputComp = (
                    <ReCaptcha
                      sitekey={RECAPTCHA_KEY}
                      action='ownEnquire'
                      verifyCallback={async (token: any) => {
                        if (formik.values['recaptcha'] !== token)
                          await formik.setFieldValue('recaptcha', token, false)
                        localStorage.setItem('recaptcha', token)
                        setRecaptchaDisableSubmitHero(false)
                      }}
                    />
                  )
                } else {
                  inputComp = (
                    <>
                      {getLabel(field.help_text || field.label)}
                      <TextInput
                        placeholder={field.help_text || field.label}
                        name={field.name}
                        type={field.type}
                        color='black'
                        borderColor={isInvalid ? 'red' : 'white'}
                        onChange={(e: any) => {
                          if (field.name === 'postcode') {
                            deboundRequest(setChoosenPostCode, e.target.value)
                          }
                          formik.setFieldValue(field.name, e.target.value)
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </>
                  )
                }
                break
              }
              default:
                inputComp = (
                  <>
                    {getLabel(field.help_text || field.label)}
                    <TextInput
                      placeholder={field.help_text || field.label}
                      name={field.name}
                      type={field.type}
                      defaultValue={field.value}
                      color='black'
                      borderColor={isInvalid ? 'red' : 'white'}
                      onChange={(e: any) => {
                        if (field.name === 'postcode') {
                          deboundRequest(setChoosenPostCode, e.target.value)
                        }
                        formik.setFieldValue(field.name, e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </>
                )
                break
            }

            if (field.field_block === 'privacypolicy') {
              inputComp = <PrivacyField field={field} formik={formik} />
            }

            return field.label == 'utm_source' ||
              field.label == 'utm_campaign' ? null : (
              <F.FormGroup
                style={{
                  display:
                    field.field_block === 'recaptcha' ? 'none' : undefined
                }}
                key={idx}
              >
                {inputComp}
                <F.ErrorMessage>
                  {formik?.errors[field.name] &&
                    formik?.touched[field.name] &&
                    formik?.errors[field.name]}
                </F.ErrorMessage>
              </F.FormGroup>
            )
          })}
        {formik.isSubmitting ? (
          <G.SpinnerWrap isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}>
            <G.Spinner />
          </G.SpinnerWrap>
        ) : !success ? (
          <EnquiryFormRow justify='center'>
            <Button
              type='submit'
              color={
                template === PAGE_TEMPLATE.YOU_FITNESS ? 'blue-deep' : 'blue'
              }
              disabled={formik.isSubmitting || recaptchaDisableSubmitHero}
            >
              {submitText || template === PAGE_TEMPLATE.YOU_FITNESS
                ? 'Send Enquiry'
                : 'SUBMIT'}
            </Button>
            {hasRecaptcha && (
              <ReCaptcha
                sitekey={RECAPTCHA_KEY}
                action='ownEnquire'
                verifyCallback={async (token: any) => {
                  if (formik.values['recaptcha'] !== token)
                    await formik.setFieldValue('recaptcha', token, false)
                  localStorage.setItem('recaptcha', token)
                  setRecaptchaDisableSubmitHero(false)
                }}
              />
            )}
          </EnquiryFormRow>
        ) : null}
      </F.Form>
    </EnquireFormHeroWrapper>
  ) : (
    <EnquireFormWrapper template={template}>
      <F.Form onSubmit={formik.handleSubmit}>
        <EnquireFormContent>
          {success ? (
            <>
              <EnquireFormHeader>
                {'Your enquiry has been sent.'}
              </EnquireFormHeader>
              <G.Space height={1} />
            </>
          ) : (
            <EnquireFormHeader>{formHeader}</EnquireFormHeader>
          )}

          {!success &&
            fields?.map((field, _idx) => {
              let inputComp
              const isInvalid =
                formik &&
                formik.errors[field.name] &&
                formik.touched[field.name]
              switch (field.type) {
                case 'text_area': {
                  inputComp = textAreaInput(field, isHero, !!isInvalid)
                  break
                }
                case 'select': {
                  const selectOptions = getChoices(field.choices)
                  inputComp = (
                    <DropdownStyled
                      name={field.name}
                      options={selectOptions}
                      classNamePrefix={
                        isYouFitnessTemplate
                          ? isInvalid
                            ? 'error you-fitness'
                            : 'you-fitness'
                          : ''
                      }
                      onChange={(option: SelectOption) =>
                        formik.setFieldValue(field.name, option.value)
                      }
                      components={dropdownComponents}
                      onBlur={formik.handleBlur}
                      placeholder={field.help_text || field.label}
                    />
                  )
                  break
                }
                case 'checkbox': {
                  const selectOptions = getChoices(field.choices)
                  inputComp = (
                    <>
                      {selectOptions.map(op => {
                        return (
                          <CheckBox
                            name={field.name}
                            type={field.type}
                            value={op.value}
                            label={op.label}
                            color='black'
                            labelColor='white'
                            borderColor={isInvalid ? 'red' : 'white'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        )
                      })}
                    </>
                  )
                  break
                }
                case 'radio': {
                  const selectOptions = getChoices(field.choices)
                  inputComp = (
                    <>
                      {selectOptions.map(op => {
                        return (
                          <Radio
                            name={field.name}
                            type={field.type}
                            value={op.value}
                            label={op.label}
                            color='black'
                            borderColor={isInvalid ? 'red' : 'white'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        )
                      })}
                    </>
                  )
                  break
                }
                case 'input': {
                  if (field.field_block == 'recaptcha') {
                    inputComp = (
                      <ReCaptcha
                        sitekey={RECAPTCHA_KEY}
                        action='ownEnquire'
                        verifyCallback={async (token: any) => {
                          if (formik.values['recaptcha'] !== token)
                            await formik.setFieldValue(
                              'recaptcha',
                              token,
                              false
                            )
                          localStorage.setItem('recaptcha', token)
                          setRecaptchaDisableSubmit(false)
                        }}
                      />
                    )
                  } else {
                    inputComp = (
                      <TextInput
                        placeholder={field.help_text}
                        name={field.name}
                        type={field.type}
                        color='black'
                        borderColor={isInvalid ? 'red' : 'white'}
                        onChange={(e: any) => {
                          if (field.name === 'postcode') {
                            deboundRequest(setChoosenPostCode, e.target.value)
                          }
                          formik.setFieldValue(field.name, e.target.value)
                        }}
                        onBlur={formik.handleBlur}
                      />
                    )
                  }
                  break
                }
                default:
                  inputComp = (
                    <TextInput
                      placeholder={field.help_text || field.label}
                      name={field.name}
                      type={field.type}
                      color='black'
                      borderColor={isInvalid ? 'red' : 'white'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  )
                  break
              }

              if (field.field_block === 'privacypolicy') {
                inputComp = <PrivacyField field={field} formik={formik} />
              }

              return field.label == 'utm_source' ||
                field.label == 'utm_campaign' ? null : (
                <F.FormGroup
                  style={{
                    display:
                      field.field_block === 'recaptcha' ? 'none' : undefined
                  }}
                >
                  {inputComp}
                  <F.ErrorMessage>
                    {formik?.errors[field.name] &&
                      formik?.touched[field.name] &&
                      formik?.errors[field.name]}
                  </F.ErrorMessage>
                </F.FormGroup>
              )
            })}
        </EnquireFormContent>
        {formik.isSubmitting ? (
          <G.SpinnerWrap isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}>
            <G.Spinner />
          </G.SpinnerWrap>
        ) : !success ? (
          <EnquiryFormRow justify='center'>
            <Button
              type='submit'
              color={
                template === PAGE_TEMPLATE.YOU_FITNESS ? 'blue-deep' : 'blue'
              }
              disabled={formik.isSubmitting || recaptchaDisableSubmit}
            >
              {submitText || template === PAGE_TEMPLATE.YOU_FITNESS
                ? 'Send Enquiry'
                : 'SUBMIT'}
            </Button>
            {hasRecaptcha && (
              <ReCaptcha
                sitekey={RECAPTCHA_KEY}
                action='ownEnquire'
                verifyCallback={async (token: any) => {
                  if (formik.values['recaptcha'] !== token)
                    await formik.setFieldValue('recaptcha', token, false)
                  localStorage.setItem('recaptcha', token)
                  setRecaptchaDisableSubmit(false)
                }}
              />
            )}
          </EnquiryFormRow>
        ) : null}
      </F.Form>
    </EnquireFormWrapper>
  )
}

export default OwnerEnquireForm
